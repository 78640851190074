import React from "react"
import { SmallText } from "../assets/primitives"
import PrimaryButton from "../view/button"
import { withCookies } from "react-cookie"

import Strings from "../assets/strings"

class Cookie extends React.PureComponent {
  constructor(props) {
    super(props)
    const { cookies } = props
    const cookieState = cookies.get("acceptedCookie")

    this.state = {
      acceptedCookies: cookieState ? cookieState : "true",
    }
  }

  _acceptCookies = () => {
    const { cookies } = this.props
    let now = new Date()
    let expires = new Date()
    expires.setMonth(now.getMonth() + 6)

    cookies.set("acceptedCookie", "true", {
      path: "/",
      expires: expires,
    })
    this.setState({ acceptedCookies: "true" })
  }

  _renderCookieBanner = () => {
    this.setState({
      acceptedCookies: this.props.cookies.get("acceptedCookie")
        ? this.props.cookies.get("acceptedCookie")
        : "false",
    })

    if (this.state.acceptedCookies === "true") {
      return <></>
    } else if (this.state.acceptedCookies === "false") {
      return (
        <div className="cookie-banner">
          <div className="cookie-banner-content">
            <SmallText
              dangerouslySetInnerHTML={{
                __html: Strings.cookieBanner.text,
              }}
            />
            <PrimaryButton
              onClick={this._acceptCookies}
              text={Strings.cookieBanner.buttonText}
            />
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  render() {
    return this._renderCookieBanner()
  }
}

export default withCookies(Cookie)
