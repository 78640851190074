const ColorDefinitons = {
  white: "#fff",
  dark: "#373434"
};

const Defintions = {
  boxBackgroundColor: ColorDefinitons.white,
  textColorDark: ColorDefinitons.dark,
  textColorLight: ColorDefinitons.white
};

const Colors = { ...ColorDefinitons, ...Defintions };

export default Colors;
