import React from "react"
import styled from "styled-components"

export default function SecondaryButton({onClick, text}) {
  return(
    <Wrapper onClick={onClick}>
      <Button>{text}</Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Button = styled.button`
  font-family: "IBM Plex Sans", sans-serif !important;
  background-color: initial !important;
 
  font-size: 14px;

  text-transform: uppercase;

  cursor: pointer;
 
  position: relative;
  z-index: 1;
  transition: all 0.2s;
  
  :focus {
    outline: none;
  }
  
  text-align: right;
  display: inline-block;
  padding: 0.5rem;
  color: #524e4e;
  box-sizing: border-box;
  border: 1px solid #524e4e;
  border-radius: 8px;
  :hover {
    color: #373434;
    border: 1px solid #373434;
  }
`