import React from "react"
import styled from "styled-components"

export default function PrimaryButton({ onClick, text, active = true }) {
  return (
    <Wrapper onClick={active ? onClick : null}>
      <Button active={active}>{text}</Button>
      {active ? <ButtonShadow /> : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const Button = styled.button`
  font-family: "IBM Plex Sans", sans-serif !important;
  background-color: initial !important;
  border: none !important;
  font-size: 16px;

  text-transform: uppercase;

  background-image: linear-gradient(
    135deg,
    rgba(71, 83, 0, 0.8) 0%,
    rgba(0, 72, 63, 0.8) 100%
  );

  background-image: linear-gradient(
    135deg,
    rgba(144, 168, 0, 0.8) 0%,
    rgba(0, 184, 163, 0.8) 100%
  );

  background-image: ${props =>
    props.active
      ? "linear-gradient(\n    135deg,\n    rgba(144, 168, 0, 0.8) 0%,\n    rgba(0, 184, 163, 0.8) 100%\n  )"
      : "linear-gradient(\n    135deg,\n    rgba(71, 83, 0, 0.8) 0%,\n    rgba(0, 72, 63, 0.8) 100%\n  )"};

  color: white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 600 !important;
  position: relative;
  z-index: 1;
  transition: all 0.2s;
  ${Wrapper}:hover & {
    background-image: linear-gradient(
      135deg,
      rgba(144, 168, 0, 1) 0%,
      rgba(0, 184, 163, 1) 100%
    );
  }

  :focus {
    outline: none;
  }
`

const ButtonShadow = styled.div`
  background-image: linear-gradient(135deg, #90a900 0%, #00b8a3 100%);
  position: absolute;
  height: 20px;
  width: 90%;
  bottom: -0rem;
  border-radius: 8px;
  filter: blur(11px);
  left: 5%;
  transition: all 0.2s;
  z-index: 0;
  transform: translateY(0rem);
  ${Wrapper}:hover & {
    transform: translateY(0.2rem);
  }
`
