import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { SmallText } from "../assets/primitives"

import "./layout.css"

import { setConfiguration } from "react-grid-system"
import { containerWidthsConfid } from "../assets/styleconfig"
import SecondaryButton from "../view/secondaryButton"

import { CookiesProvider } from "react-cookie"
import Cookie from "./cookie"

class Layout extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data =>
          this.props.showCookieBanner === false ? (
            <main>{this.props.children}</main>
          ) : (
            <CookiesProvider>
              <Cookie />
              <main>{this.props.children}</main>
            </CookiesProvider>
          )
        }
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
