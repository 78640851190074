const strings = {
  landingPage: {
    meta: {
      siteRoot: "https://www.brive.io",
      title: "BRIVE - Fahrzeugflotten mit Telematik effizienter machen",
    },
    header: {
      headline: "Die einfachste Art Fahrzeugkosten zu senken.",
      text:
        "Sparsames Fahrverhalten belohnen und damit Kosten senken. Das ist BRIVE.",
      button: "Kontaktieren",
      changeLanguage: {
        text: "English",
        link: "https://eng.brive.io",
      },
    },
    intro: {
      headline: "Den größten Anteil an Flottenkosten haben Ihre Fahrer",
      text:
        "Umso wichtiger sind deshalb motivierte Mitarbeiter, die mit ihrer Fahrweise Rücksicht auf die Fahrzeuge, die laufenden Kosten und die Umwelt nehmen. BRIVE senkt die Betriebskosten Ihres Fuhrparks, indem es Mitarbeiter finanziell an den Ersparnissen bei sparsamerer Fahrweise beteiligt.",
      chartDescription: "Beeinflussbarer Anteil des Fahrers an Flottenkosten",
      chartPercent: 63,
      chartUpTo: "bis zu",
      bulletPoints: [
        "Kraftstoffverbrauch",
        "Unfallkosten",
        "Versicherungskosten",
        "Wartung",
        "Verschleiß",
      ],
    },
    hotItWorks: {
      headline: "Kostensenkung als Dienstleistung, so funktioniert BRIVE",
      text:
        "BRIVE besteht aus mehreren Komponenten: Analyse, Bewertung und anschließende Belohnung der Fahrer.",
      boxes: [
        {
          title: "Analyse",
          text:
            "Die BRIVE App liest die Sensoren im Smartphone aus und bereitet die Daten auf.",
        },
        {
          title: "Bewertung",
          text:
            "Algorithmen berechnen anhand der Sensoren eine Bewertung des Fahrstils für den einzelnen Fahrer.",
        },
        {
          title: "Belohnung",
          text:
            "Aus der Bewertung ergibt sich ein Bonus, den der Fahrer auf die BRIVE Kreditkarte steuerfrei überwiesen bekommt.",
        },
        {
          title: "Verbesserung",
          text:
            "Je besser der Fahrstil, desto größer ist die Ersparnis für Ihre Flotte und der Gehaltsbonus für Ihre Mitarbeiter.",
        },
      ],
    },
    analyseSection: {
      number: 1,
      title: "Analyse von Sensorwerten aus dem Smartphone",
      description:
        "BRIVE nutzt eine Vielzahl von Sensoren im Smartphone, um herauszufinden wie das Fahrzeug gefahren wird. Anhand dieser Sensoren werden Ereignisse berechnet, die einen Aufschluss über das Fahrverhalten geben (z.B. starke Bremsung oder Geschwindigkeitsüberschreitung).",
      img: "",
    },
    ratingSection: {
      number: 2,
      title: "Bewertung des Fahrstils verständlich gemacht",
      description:
        "Aus den Ereignissen berechnet BRIVE Punktzahlen in verschiedenen Kategorien, die für den Fahrer verständlich dargestellt werden. Daraus erhalten sie Einblicke in ihre Fahrweise und können sich gezielt verbessern. Sie profitieren von niedrigeren Fahrzeugkosten",
      sections: [
        {
          title: "Anfahrverhalten",
          scoring: 80,
        },
        {
          title: "Bremsverhalten",
          scoring: 67,
        },
        {
          title: "Geschwindigkeit",
          scoring: 43,
        },
        {
          title: "Leerlauf",
          scoring: 58,
        },
        {
          title: "Handynutzung",
          scoring: 91,
        },
        {
          title: "Kurvenverhalten",
          scoring: 85,
        },
      ],
    },
    rewardSection: {
      number: 3,
      title: "Belohnung berechnet nach der Qualität des Fahrstils",
      description:
        "Aus der Bewertung des Fahrstils berechnet BRIVE einen Geldbetrag, der dem Fahrer als steuerfreie Sachleistung auf die BRIVE Kreditkarte überwiesen wird - eine clevere Gehaltserhöhung ohne Sozialabgaben.*",
      notice:
        "*bis maximal 44€ monatlich steuerfrei, danach Pauschalbesteuerung",
    },
    improvementSection: {
      number: 4,
      title: "Die Effizienz der Flotte steigert sich signifikant",
      description:
        "Einsparungen bei Verbrauch, Unfallkosten und Kosten für Wartung und Verschleiß steigern die Profitabilität Ihrer Flotte. Gleichzeitig motivieren Sie Ihre Mitarbeiter durch zusätzliche Gehaltsleistungen. ",
    },
    advantages: {
      title: "Weitere Vorteile mit der Nutzung von BRIVE für ihre Flotte",
      sections: [
        {
          icon: "heart.svg",
          title: "Höhere Mitarbeiterzufriedenheit",
          description:
            "Durch den ausgezahlten Bonus steigert sich nicht nur die Effizienz ihrer Flotte, sondern gleichzeitig auch die Zufriedenheit ihrer Mitarbeiter.",
        },
        {
          icon: "plus.svg",
          title: "BRIVE berücksichtigt Ihre Bewertungsfaktoren",
          description:
            "BRIVE beschränkt sich nicht nur auf die Fahrweise an sich. Sollten weitere Faktoren wie z.B. Kundenzufriedenheit oder Pünktlichkeit berücksichtigt werden, ist dies einfach möglich.",
        },
        {
          icon: "camera.svg",
          title: "WER MÖCHTE SCHON ÜBERWACHT WERDEN?",
          description:
            "BRIVE nimmt Ihnen die Arbeit Ihrer Flotte ab und informiert Sie auf Flottenebene über den Fortschritt. Überwachung der Mitarbeiter durch den Arbeitgeber ist somit unmöglich.",
        },
      ],
    },
    team: {
      headline: "Team",
      members: [
        {
          image: "roman_safronov.png",
          name: "Roman Safronov",
          title: "CEO & Co-Founder",
        },
        {
          image: "daniel_janke.png",
          name: "Daniel Janke",
          title: "Developer, Designer & Co-Founder",
        },
        {
          image: "jan_schmutz.png",
          name: "Jan Schmutz",
          title: "Backend-Developer",
        },
      ],
    },
    board: {
      headline: "Berater",
      members: [
        {
          image: "philipp_roehl.png",
          name: "Philipp Roehl",
          title:
            "Philipp Roehl leitete mehrere Jahre die Strategieabteilung des internationalen Speditionsgeschäft bei der Deutschen Post DHL Group und unterstützt mit Netzwerkexpertise.",
        },
        {
          image: "david_linner.png",
          name: "Prof. Dr. David Linner",
          title:
            "David Linner ist Professor der angewandten Informatik an der design akademie berlin und erfolgreicher Serienunternehmer. Er steht BRIVE bei technischen Fragen zur Seite.",
        },
        {
          image: "wolfgang_schulz.png",
          name: "Prof. Dr. Wolfgang Schulz",
          title:
            "Wolfgang Schulz unterstützt mit Netzwerkexpertise. Er ist Sachverständiger der Bundesregierung für Mobilitätsprojekte und Lehrstuhlinhaber an der Zeppelin Universität.",
        },
        {
          image: "mike_linthe.png",
          name: "Mike Linthe",
          title:
            "Mike Linthe unterstützt BRIVE in operativen Fragestellungen. Als Manager bei PwC Deutschland und erfolgreicher Gründer bringt er langjährige Projekterfahrung mit.",
        },
        {
          image: "petra_sauer.png",
          name: "Prof. Dr. Petra Sauer",
          title:
            "Petra Sauer ist Professorin der Informatik an der Beuth Hochschule für Technik Berlin. Sie ist renommierte Expertin für skalierbares Data Mining auf räumlich-zeitlichen Sensordaten.",
        },
      ],
    },
    support: {
      title: "Gefördert durch",
      images: [
        "europaeische_union_sozialfonds.png",
        "efre.png",
        "berlin.png",
        "berliner_startup_stipendium.png",
        "project_together.png",
        "zu.png",
      ],
    },
    faq: {
      headline: "Häufige Fragen",
      questions: [
        {
          question: "Für welche Firmen ist BRIVE geeignet ?",
          answer:
            "Ob Paketzusteller, Pizzadienst oder Handwerksbetrieb - BRIVE optimiert das Fahrverhalten unabhängig Ihres Geschäftsschwerpunktes. Fordern Sie weitere Informationen für Ihren Betrieb an unter info@brive.io ",
        },
        {
          question: "Wie viel kostet BRIVE ?",
          answer:
            "Die Kosten richten sich nach Anzahl der Nutzer innerhalb Ihrer Flotte. Aufgrund des Kostenvorteils durch die Nutzung des Smartphones ist BRIVE günstig im Betrieb und zudem schnell und einfach zu implementieren. ",
        },
        {
          question: "Wie hoch sind die Einsparungen mit BRIVE ?",
          answer:
            "Konkrete Schätzungen sind abhängig von den Spezifikationen des Fuhrparks wie Laufleistung, Fahrzeugtyp und Verbrauch. Vergleichswerte zeigen Einsparpotentiale von bis zu 15% der variablen Kosten Ihrer Flotte. Durch die steueroptimierte Gehaltserhöhung haben Sie zudem ein zusätzliches Werkzeug, um Mitarbeiter zu motivieren. Wir beraten Sie gerne! ",
        },
        {
          question: "Ich bin interessiert.",
          answer:
            "Schreiben Sie uns eine Nachricht an info@brive.io oder besuchen Sie uns in unserem Büro in Berlin - wir freuen uns! ",
        },
      ],
    },
    contact: {
      contactUs: "Kontaktieren",
      headline:
        "Machen Sie Ihre Flotte effizienter und belohnen Sie Ihre Mitarbeiter.",
      subheadline: "",
      newsletter: {
        tab: "Newsletter abonnieren",
        placeholder: "E-Mail-Adresse",
        privacy:
          "Hiermit akzeptiere ich die Erklärung zum Datenschutz gemäß DSGVO und wünsche, dass BRIVE mich über Neuigkeiten informiert.",
        button: "Abonnieren",
      },
      mail: "E-Mail Adresse",
      message: "Ihre Nachricht",
      send: "Nachricht Senden",
      contact: {
        tab: "Kontaktieren",
      },
      privacePolicyText:
        'Ihre Daten werden ausschließlich für die Bearbeitung Ihres Anliegens verwendet und werden nach 90 Tagen gelöscht. Hier finden Sie unsere Erklärung zum <a class="dark" href="/datenschutzerklaerung"> Datenschutz </a>.',
    },
  },
  resetPassword: {
    title: "Neues Passwort vergeben",
    text: "Setze dein Passwort zurück",
    password: "Passwort",
    passwordRepeat: "Passwort wiederholen",
    resetPasswordButton: "Passwort zurücksetzen",
    requirements:
      "Dein Passwort muss mindestens 8 Zeichen lang sein, einen Klein-, einen Großbuchstaben und eine Ziffer.",
  },
  verifyMail: {
    title: "E-Mail-Adresse bestätigen",
  },
  routing: {
    imprint: "/impressum",
    imprintText: "Impressum",
    imprintComponentPath: "src/containers/Imprint_GER",
    privacyPolicy: "/datenschutzerklaerung",
    privacyPolicyText: "Datenschutzerklärung",
    privacyPolicyPath: "src/containers/PrivacyPolicy_GER",
  },
  cookieBanner: {
    text:
      "BRIVE benutzt Cookies, um seinen Nutzern das beste Webseiten-Erlebnis zu ermöglichen. Außerdem werden teilweise auch Cookies von Diensten Dritter gesetzt. Weiterführende Informationen erhalten Sie in der <a class='dark' href='/datenschutzerklaerung'> Datenschutzerklärung </a> von BRIVE.",
    buttonText: "Verstanden",
  },
  appDriverFaq: {
    headline: "Häufige Fragen von Fahrern",
    sections: [
      {
        headline: "Fahranalyse",
        questions: [
          {
            question: "Welche Daten werden genutzt?",
            answer:
              "Wir nutzen die Daten verschiedener Sensoren aus deinem Smartphone. Diese Sensoren sind das Gyroskop, das Magnetometer, der Beschleunigungssensor und der GPS-Sensor. Aus diesen Sensordaten und weiteren externen Daten berechnen wir deine Punktzahl. Deine Punktzahlen werden nur im Durchschnitt mit deinen Kollegen weitergegeben. Siehe hierzu auch unsere Datenschutzbedingungen an.",
          },
          {
            question: "Wie kommt meine Punktzahl zustande?",
            answer:
              "Deine Punktzahl wird aus verschiedenen Kategorien berechnet: Brems-, Anfahr-, und Kurvenverhalten, sowie Handynutzung, Geschwindigkeit und Verbrauch. Deine Punktzahl beträgt zwischen 0 und 100 (Gesamtscore) und für jede Kategorie zwischen 0 und 5.",
          },
          {
            question: "Kann ich eine Fahrt löschen?",
            answer:
              "Wenn du einen guten Grund für das Löschen einer Fahrt hast, kannst du beim Support eine Anfrage stellen. Einzelne Ereignisse (wie eine harte Bremsung) können wir nicht löschen.",
          },
          {
            question:
              "Ich habe aus Versehen eine private Fahrt getrackt. Was muss ich tun?",
            answer: "Stelle hierzu eine Anfrage an den Support.",
          },
          {
            question: "Eine Bewertung stimmt nicht. Was kann ich tun?",
            answer: "Stelle hierzu eine Anfrage an den Support.",
          },
          {
            question: "Meine Fahrt wurde nicht aufgenommen. Was kann ich tun?",
            answer:
              "Überprüfe zuerst, ob du im Flugmodus, den Energiesparmodus an hattest oder die Standortinformationen deaktiviert hattest. Falls nicht, stelle eine Anfrage an den Support.",
          },
        ],
      },
      {
        headline: "Meine Belohnung",
        questions: [
          {
            question:
              "Ich habe mehr Punkte in einem Monat als in einem anderem, aber meine Belohnung fällt geringer aus wieso?",
            answer:
              "Die Höhe deiner Belohnung setzt sich nicht nur aus deiner Gesamtpunktzahl für einen Monat zusammen, sondern auch aus der Verbesserung zum Vormonat.",
          },
          {
            question: "Wie wird die Belohnung berechnet?",
            answer:
              "Wir schauen uns dazu deine Punktzahl an und vergleichen ihn mit deinen Kollegen. Dazu kommt auch die Verbesserung zum Vormonat (ebenfalls im Vergleich zu deinen Kollegen).",
          },
          {
            question: "Wieviel Belohnung kann ich bekommen?",
            answer:
              "Die Höhe deiner Belohnung hängt von deinem Arbeitgeber ab, aber üblich sind zwischen 0 und 40€.",
          },
          {
            question: "Wann kommt meine Belohnung an?",
            answer:
              "Die Höhe deiner Belohnung wird am 1. des darauffolgenden Monats berechnet. Danach dauert es noch ca. 10 Tage bis das Geld auf deiner Kreditkarte ist.",
          },
          {
            question: "Kann ich das Geld auch auf mein Konto auszahlen lassen?",
            answer:
              "Nein, das ist leider nicht möglich. Eine Steuersparmöglichkeit wäre damnit ausgeschlossen.",
          },
        ],
      },
      {
        headline: "Meine Kreditkarte",
        questions: [
          {
            question:
              "Ich habe meine Karte verloren oder sie wurde gestohlen. Was muss ich tun?",
            answer:
              "Bitte wende Dich sofort an unseren Kreditkartenpartner Givve via +49 89 66 06 26 15 00 oder per Mail an office@givve.com.",
          },
          {
            question: "Warum kann ich kein Geld abheben?",
            answer:
              "Wenn du dein Geld abheben könntest, müsstest du mehr Steuern zahlen, da es sich um reguläres Gehalt handeln würde. Daher haben wir die Auszahlungsfunktion gesperrt.",
          },
          {
            question: "Wo kann ich meine Karte verwalten?",
            answer:
              "Hier: https://card.givve.com/login. Später auch in der App.",
          },
          {
            question: "Wo finde ich weitere Informationen zur Kreditkarte?",
            answer: "Hier: https://www.givve.com/de/support/faq-arbeitnehmer",
          },
        ],
      },
      {
        headline: "Sonstiges",
        questions: [
          {
            question: "Was passiert wenn ich bei meinem Arbeitgeber kündige?",
            answer:
              "Bitte erinnere ihn daran, uns eine Nachricht zu senden. Wir werden dann deinen Account sperren. Deine Kreditkarte kannst du weiter nutzen. Allerdings erhältst du keine Zahlungen mehr von deinem Arbeitgeber. Du kannst dir aber auch weiterhin von deinem Konto Geld auf die Kreditkarte laden.",
          },
          {
            question:
              "Ich arbeite jetzt mehr/weniger Stunden in der Woche. Was muss ich tun?",
            answer: "Bitte informiere uns und deinen Arbeitgeber darüber.",
          },
          {
            question: "Ich möchte BRIVE nicht mehr nutzen. Was muss ich tun?",
            answer:
              "Das finden wir natürlich schade, respektieren wir aber natürlich. Gehe dazu in die Einstellungen. Dort findest du die Möglichkeit die Teilnahme zu beenden.",
          },
        ],
      },
    ],
  },
}

export default strings
